import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  id: "app"
};
export default {
  __name: 'App',
  setup(__props) {
    // import ScaleBox from "vue3-scale-box";
    // import { onMounted } from 'vue';
    // import devPixelRatio from "@/utils/devicePixelRatio.js";

    // onMounted(() => {
    //   new devPixelRatio().init(); // 初始化页面比例
    // });

    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_router_view)]);
    };
  }
};