import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
   // 聊天机器人
   {
    path: '/',
    name: 'html',
    component: () => import('@/components/Html-w.vue')
  },
 
   {
    path: '/select',
    name: 'select',
    component: () => import('@/components/select/index.vue')
  },
 
   {
    path: '/payment',
    name: 'payment',
    component: () => import('@/components/payment/index.vue')
  },
   {
    path: '/history',
    name: 'history',
    component: () => import('@/components/history/index.vue')
  },
 
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;