import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
// import htmlToPdf from './store/'
// import hljs from "highlight.js";
// import "highlight.js/styles/atom-one-dark.css" //样式
// import 'element-plus/dist/index.css'
import VueLazyload from 'vue3-lazy'
// 引入echarts

import '@/assets/fonts/font.css'

// import wx from 'weixin-js-sdk';

const app = createApp(App);

// 添加到全局属性，这样可以在组件中使用 this.$wx 访问
// app.config.globalProperties.$wx = wx;



// //创建v-highlight全局指令
// app.directive("highlight",function (el) {
//         const blocks = el.querySelectorAll("pre code");
//         blocks.forEach((block:any)=>{
//         hljs.highlightBlock(block)
//     })
// })

createApp(App).use(store).use(router)
.provide('$axios',axios).mount('#app')

